import moment from "moment/moment";
import { getDefaultLocale } from "../locales";

const momentLocaleReq = require.context("moment/locale/", true, /^(?!.\/index).*.js$/);
const momentLocales: string[] = [];

momentLocaleReq.keys().forEach((key: string) => {
  const regex = /.\/(.*?).js$/;
  const moduleName = (regex.test(key) && key.match(regex)![1]) as string;
  momentLocales.push(moduleName);
});

const applyLocale = momentLocales.filter(item => getDefaultLocale().includes(item)).shift();

if (applyLocale) {
  require(`moment/locale/${applyLocale}`);
}

type dateDisplayType = (isoString: string) => string;

const dateDisplay: dateDisplayType = isoString => {
  const nowDate = new Date();
  const compareDate = new Date(isoString);
  const dayGap = nowDate.getDate() - compareDate.getDate();

  const resultDate = dayGap > 7 ? moment(isoString).format("ll") : moment(isoString).fromNow();

  return resultDate;
};

export default dateDisplay;
