import cookie from "js-cookie";
import queryString from "query-string";

const { search } = window.location;

const queryToken = queryString.parse(search).token;

if (queryToken) cookie.set("access_token", queryToken as string);

export const defaults = {
  isLoggedIn: Boolean(cookie.get("token")) || false,
  theme: cookie.get("theme") || "light"
};



export const resolvers = {
  Mutation: {
    userLogin: (_: any, { token }: { token: string }, { cache }: { cache: any }) => {
      cookie.set("token", token, { domain: ".ju-rie.net" });
      cache.writeData({ data: { isLoggedIn: true } });
      window.location.replace(window.location.origin);
      return null;
    },
    userLogout: () => {
      cookie.remove("token");
      cookie.remove("token", { domain: ".ju-rie.net" });
      window.location.replace(window.location.origin);
      return null;
    },
    setTheme: (_: any, { theme }: { theme: string }, { cache }: { cache: any }) => {
      cookie.set("theme", theme);
      cache.writeData({ data: { theme } });
      return null;
    }
  }
};
