import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styles/theme";
import { publicRules, notLoginRules } from "components/App";

const ReactSnapComponent: React.FC = () => {
  useEffect(() => {
    setTimeout(() => {
      document.getElementById("react-snap")?.remove();
    }, 1000);
  }, []);
  return <ReactSnap id={"react-snap"}>{publicRules.concat(notLoginRules).map(item => item.path.map((item, index) => <Link to={item} key={index}></Link>))}</ReactSnap>;
};

ReactSnapComponent.defaultProps = {};

export default ReactSnapComponent;

const ReactSnap = styled.div`
  display: none;
`;
