import React from "react";
import styled from "styles/theme";
import { css } from "@emotion/core";

const TitleComponent: React.FC<ITitleProps> = ({ shape, children }) => <Title shape={shape}>{children}</Title>;

TitleComponent.defaultProps = {
  shape: "H1"
};

export default TitleComponent;

const HEADING1 = css`
  font-size: 30px;
  line-height: 46px;
`;

const HEADING2 = css`
  font-size: 14px;
  line-height: 22px;
`;

const HEADING3 = css`
  font-size: 18px;
  line-height: 26px;
`;

const HEADING4 = css`
  font-size: 16px;
  line-height: 24px;
`;

const Title = styled.h2<ITitleProps>`
  color: ${p => (p.shape === "H5" ? p.theme.COLORS.TEXT_BRAND : p.theme.COLORS.TEXT_REGULAR)};
  font-weight: 500;
  ${p => p.shape === "H1" && HEADING1}
  ${p => p.shape === "H2" && HEADING2}
  ${p => p.shape === "H3" && HEADING3}
  ${p => p.shape === "H4" && HEADING4}
  ${p => p.shape === "H5" && HEADING4}
`;

const TEXT = {
  TYPE01: css`
    font-size: 18px;
    line-height: 26px;
  `,
  TYPE02: css`
    font-size: 16px;
    line-height: 24px;
  `,
  TYPE03: css`
    font-size: 14px;
    line-height: 22px;
  `
};

export { TEXT };
