import React, { useState } from "react";
import withLayout from "layouts/EmptyLayout";
import styled from "@emotion/styled";
// import { useTranslation } from "react-i18next";
import ReactSnap from "components/Snap";

import { Typography, Form, Input, Button, Checkbox, Modal } from "antd";
import { Rule, FormItemProps } from "antd/lib/form";
import { CheckCircleTwoTone, ExclamationCircleTwoTone } from "@ant-design/icons";

import { LocalLink } from "styles";

import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { LOGIN } from "api/User";

const CLIENT_LOGIN = gql`
  mutation userLogin($token: String!) {
    userLogin(token: $token) @client
  }
`;

const LoginView: React.FC<RouteComponentProps> = () => {
  // const { t } = useTranslation("Test");

  const { Title } = Typography;

  const formLayout = {
    wrapperCol: { span: 24 }
  };

  // const tailLayout = {
  //   wrapperCol: { span: 24 }
  // };

  // const buttonLayout = {
  //   wrapperCol: { offset: 0, span: 24 }
  // };

  const [login, setLogin] = useState<boolean | undefined>(undefined);

  const [ClientLogin] = useMutation(CLIENT_LOGIN);

  const [LoginMutation] = useMutation(LOGIN, {
    onCompleted: data => {
      if (data.login.length > 1) {
        ClientLogin({
          variables: {
            token: data.login
          }
        });
      }
    },
    onError: err => {
      console.log(err, "err");
      setLogin(false);
    }
  });

  const onFinish = (values: any) => {
    console.log("Success:", values);

    const { email, password } = values;

    LoginMutation({
      variables: {
        email,
        password
      }
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const rules: rulesBaseType<Rule> = {
    email: [
      {
        type: "email",
        message: "이메일형식이 아닙니다."
      },
      { required: true, message: "로그인에 사용할 이메일을 입력해주세요." }
    ],
    password: [{ required: true, message: "비밀번호를 입력해주세요" }]
  };

  const formItems: FormItemProps[] = [
    {
      name: "email",
      children: <Input placeholder={"E-MAIL"} size={"large"} />
    },
    {
      name: "password",
      children: <Input.Password placeholder={"PASSWORD"} size={"large"} />
    },
    {
      name: "remember",
      valuePropName: "checked",
      children: <Checkbox>Remember me</Checkbox>
    },
    {
      className: "btn-area",
      children: (
        <>
          <Button type="primary" htmlType="submit" size={"large"} block>
            로그인
          </Button>
          <Button type="text" size={"large"} block>
            <LocalLink to={"/signup"}>회원가입</LocalLink>
          </Button>
        </>
      )
    }
  ];

  return (
    <Wrap>
      <Title className={"title"}>Ju-rie Works</Title>
      <Form {...formLayout} name="login" initialValues={{ remember: true }} onFinish={onFinish} onFinishFailed={onFinishFailed}>
        {formItems.map((item, index) => (
          <Form.Item {...item} rules={rules[item.name as string]} key={index} />
        ))}
      </Form>
      <Modal visible={login !== undefined && true} closable={false} maskClosable={true} footer={null} onCancel={() => setLogin(undefined)}>
        <Complete className={login === undefined ? "none " : ""}>
          {login ? (
            <CheckCircleTwoTone style={{ fontSize: "50px" }} twoToneColor={"#73d13d"} />
          ) : (
            <ExclamationCircleTwoTone style={{ fontSize: "50px" }} twoToneColor={"#cf1322"} />
          )}
          <Title level={3}>{login ? "로그인 완료" : "이메일 및 비밀번호를 잘 못 입력하였습니다."}</Title>
        </Complete>
      </Modal>
      <ReactSnap />
    </Wrap>
  );
};

export default withLayout(LoginView);

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 80vh;

  .title {
    text-align: center;
  }

  #login {
    margin: 20px auto 0;
    width: 100%;
    max-width: 360px;
  }

  .btn-area {
    text-align: center;
  }
`;

const Complete = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.none {
    opacity: 0;
  }

  & h3 {
    margin-top: 20px;
  }
`;
