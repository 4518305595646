import React from "react";
// import { gql } from "apollo-boost";
// import { useMutation } from "@apollo/react-hooks";
import cookie from "js-cookie";
import withLayout from "layouts/DefaultLayout";
import styled from "styles/theme";
import { useTranslation } from "react-i18next";
import { changeLang } from "locales";
// import { theme as themeMap, themeTypes } from "styles/theme";
import { Title, Button, Tab, Tag } from "styles";
import { TEXT } from "styles/Title";

// const CHANGE_THEME = gql`
//   mutation setTheme($theme: String!) {
//     setTheme(theme: $theme) @client
//   }
// `;

const GuideView: React.FC<RouteComponentProps> = () => {
  // const [ChangeThemeMutation] = useMutation(CHANGE_THEME);
  const { t } = useTranslation("Test");

  const handleThemeChange = (theme: string) => {
    cookie.set("theme", theme);
    window.location.reload();
  };

  const getLocale = cookie.get("locale");

  const langData = [
    {
      lang: "ko-KR",
      name: "한국어"
    },
    {
      lang: "en-US",
      name: "영어"
    }
  ];

  // const ThemeUse = themeMap[document.body.id as themeTypes];

  // console.log(window.navigator.connection, "window.navigator.connection");
  const connection = window.navigator.connection;
  return (
    <Wrap>
      <Section>
        {`effectiveType: ${connection?.effectiveType}`}
        <br />
        {`downlink: ${connection?.downlink}`}
        <br />
        {`downlinkMax: ${connection?.downlinkMax}`}
        <br />
        {`rtt: ${connection?.rtt}`}
        <br />
        {`saveData: ${connection?.saveData}`}
        <br />
        {`type: ${connection?.type}`}
        <br />
        {`onchange: ${connection?.onchange}`}
      </Section>
      {/* <Section>
        <Button className="pressd" round onClick={() => handleThemeChange("light")}>
          light 테마(기본)
        </Button>
        <Button variant="CANCEL" onClick={() => handleThemeChange("darken")}>
          darken 테마
        </Button>
      </Section> */}
      <Section>
        <Title shape="H3">제목</Title>
        <div>
          <Title shape="H1">noto/medium/30 팝업/제목 팝업타이틀</Title>
          <Text01>noto/Regular/18 리스트/제목 글 제목 입니다.</Text01>
          <Title shape="H2">noto/medium/14 리스트/제목 글 제목 입니다.</Title>
          <Title shape="H3">noto/medium/18 피드/제목 글 제목 입니다.</Title>
          <Text02>noto/Regular/16 내용 내용입니다.</Text02>
          <Text03>noto/Regular/14 댓글내용 댓글내용입니다.</Text03>
          <Title shape="H4">noto/medium/6 피드/제목 상단 타이틀</Title>
          <Title shape="H5">noto/medium/6 피드/제목 상단 게시판명</Title>
        </div>
      </Section>
      <Section>
        <Title shape="H3">다국어 테스트</Title>
        {t("test")}
        <div>
          <Tab>
            {langData.map((item, index) => (
              <button type="button" className={getLocale === item.lang ? "active" : ""} onClick={() => changeLang(item.lang)} key={index}>
                {item.name}
              </button>
            ))}
          </Tab>
        </div>
      </Section>
      <Section>
        <Title shape="H3">버튼</Title>
        <div>
          <Button type="button">기본</Button>
          <Button type="button" round>
            라운드
          </Button>
          <Button type="button" disabled>
            사용금지
          </Button>
          <Button to="test" round>
            링크
          </Button>
          <Button to="https://blog.touchclass.com/" target="_blank">
            새창 링크
          </Button>
        </div>
      </Section>
      <Section>
        <Title shape="H3">탭</Title>
        <div>
          <Tab>
            <button className="active">Tab1</button>
            <a href="#none">Tab2</a>
            <button>Tab3</button>
            <button>Tab4</button>
            <button>Tab5</button>
            <button>Tab6</button>
          </Tab>
          <Tab justify>
            <button className="active">Tab1</button>
            <a href="#none">Tab2</a>
            <button>Tab3</button>
            <button>Tab4</button>
            <button>Tab5</button>
            <button>Tab6</button>
          </Tab>
          <Tab scroll>
            <button className="active">Tab1</button>
            <a href="#none">Tab2</a>
            <button>Tab3</button>
            <button>Tab4</button>
            <button>Tab5</button>
            <button>Tab6</button>
            <button>Tab7</button>
            <button>Tab8</button>
            <button>Tab9</button>
            <button>Tab10</button>
            <button>Tab11</button>
            <button>Tab12</button>
            <button>Tab13</button>
            <button>Tab14</button>
            <button>Tab15</button>
            <button>Tab16</button>
            <button>Tab17</button>
            <button>Tab18</button>
            <button>Tab19</button>
            <button>Tab20</button>
          </Tab>
        </div>
      </Section>
      <Section>
        <Title shape="H3">태그, 멘션</Title>
        <div>
          <Tag to={"guide"}>태그</Tag>
          <Tag to={"guide"} mention>
            멘션
          </Tag>
        </div>
      </Section>
    </Wrap>
  );
};

export default withLayout(GuideView);

const Wrap = styled.div``;

const Section = styled.div`
  margin-top: 40px;

  :first-of-type {
    margin-top: 0;
  }
`;

const Text01 = styled.p`
  ${TEXT.TYPE01};
`;

const Text02 = styled.p`
  ${TEXT.TYPE02};
`;

const Text03 = styled.p`
  ${TEXT.TYPE03};
`;
