import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { v4 as uuid } from "uuid";
import { blue, grey } from "@ant-design/colors";

import Text from "./Text";

import Controller from "./Toolbar/TextToolbar";

import { fnGetPosition, fnSetPosition, fnCutHTML } from "./Position";

interface IEditorOpeions {
  header?: boolean;
  toolbar?: {
    image?: boolean;
    video?: boolean;
    audio?: boolean;
    schedule?: boolean;
    location?: boolean;
    vote?: boolean;
  };
  fullWidth?: boolean;
}

interface IEditorProps {
  value?: IEditor;
  options?: IEditorOpeions;
  onChange?(lines: IEditorLine[], representation: number): void;
}

interface ICommend {
  modify: (text: string) => void;
}

interface IEditorAddProps {
  type: EditorTypes;
  accept?: EditorAccept;
  target?: ISchedule | IVote;
  idx: number;
  name?: string;
  address?: string;
  lng?: number;
  lat?: number;
}

const EditorComponent: React.FC<IEditorProps> = ({ value, options, onChange }) => {
  options = { header: true, toolbar: { image: true, video: true, audio: true, location: true, schedule: true, vote: true }, fullWidth: false, ...options };
  const editorRef = useRef<HTMLDivElement>(null);
  const confirmRef = useRef<boolean>(false);
  const commendRef = useRef<ICommend[]>([]);

  // const [_id, setId] = useState<string | undefined>(value ? value._id : undefined);
  const [active, setActive] = useState<number>(-1);
  const [lines, setLines] = useState<IEditorLine[]>(value ? value.lines : []);
  const [open, setOpen] = useState<"SCHEDULE" | "LOCATION" | "VOTE" | undefined>();
  const [representation, setRepresentation] = useState<number>(-1);

  useEffect(() => {
    window.addEventListener("keydown", handleGlobalKeyEvent, false);
    return () => {
      window.removeEventListener("keydown", handleGlobalKeyEvent, false);
    };
  }, [lines, active]);

  useEffect(() => {
    onChange && onChange(lines, representation);
  });

  const handleOpen = async (type: "SCHEDULE" | "LOCATION" | "VOTE") => setOpen(type);

  const handleChangeHtml = (idx: number, html: string) => {
    lines[idx].html = html;
    setLines(lines);
    onChange && onChange(lines, representation);
  };

  const handleRemoveLine = async (idx: number) => {
    lines.splice(idx, 1);
    setLines(lines);
  };

  // 라인 추가 이벤트
  const handleAddLine = async ({ type, idx, accept, target, ...data }: IEditorAddProps) => {
    // 현재의 돔을 불러옵니다. ( 미존재시에 null )
    const ACTIVE_DOM = document.querySelector(`div[data-index="${idx}"]`) as HTMLDivElement;

    // 타입에 따라 추가되는 데이터가 다르기 때문에 분기하여 처리합니다.
    switch (type) {
      case "TEXT":
        {
          // 현재 돔이 존재할 때
          if (!ACTIVE_DOM) {
            // 이전이 돔을 불러옵니다.
            const PREV_DOM = document.querySelector(`div[data-index="${idx - 1}"]`) as HTMLDivElement;
            // 이전의 돔이 존재하고, 상태가 TEXT, 그리고 내용이 없을 때
            if (PREV_DOM && PREV_DOM.getAttribute("data-type") === "TEXT" && PREV_DOM.innerHTML === "") {
              // 데이터를 추가 하지 않고 이전의 TEXT에 focus 하고 Active를 맞춥니다.
              PREV_DOM.focus();
              setActive(idx - 1);
              // 그 이외의 경우
            } else {
              // TEXT의 형태로 된 데이터 추가하고 Active를 맞춥니다.
              setLines(prev => {
                prev.splice(idx, 0, { _id: uuid(), type, html: "" });
                return prev;
              });
              setActive(idx);
            }
          } else if (ACTIVE_DOM.getAttribute("data-type") === "TEXT") {
            // 현재 DOM이 TEXT 일 때
            if (ACTIVE_DOM.innerHTML === "") {
              // (idx + 1)번째 라인의 내용이 없으니 추가하지 않고 focus 합니다.
              ACTIVE_DOM.focus();
              setActive(idx);
            } else {
              // (idx + 1)번째 라인의 내용 존재하여 라인을 추가하고 추가한 라인에 focus 합니다.
              setLines(prev => {
                prev.splice(idx + 1, 0, { _id: uuid(), type, html: "" });
                return prev;
              });
              setActive(p => p + 1);
            }
          } else {
            // (idx + 1)번째 라인의 내용이 TEXT가 아니라면 아래쪽으로 라인 추가 후 focus
            setLines(prev => {
              prev.splice(idx + 1, 0, { _id: uuid(), type, html: "" });
              return prev;
            });
            setActive(idx);
          }
        }
        break;
    }
  };

  const handleGlobalKeyEvent = async (event: KeyboardEvent) => {
    try {
      // modal 또는 confirm이 여려있을때 작동 금지
      if (open || confirmRef.current) return false;
      // console.log(`Global: ${event.keyCode}`);

      // 현재 돔을 불러옵니다.
      const NOW_DOM = document.querySelector(`div[data-index="${active}"]`) as HTMLDivElement;
      // 현재 돔이 존재시
      if (NOW_DOM) {
        // 키보드 키코드에 따른 이벤트 분기
        switch (event.keyCode) {
          case 8:
            {
              // Backspace
              const PREV_DOM = document.querySelector(`div[data-index="${active - 1}"]`) as HTMLDivElement;
              const NEXT_DOM = document.querySelector(`div[data-index="${active + 1}"]`) as HTMLDivElement;

              if (PREV_DOM) {
                const prevPosition = PREV_DOM.innerText.length;
                // 상단 라인이 존재하는 경우

                if (NOW_DOM?.getAttribute("data-type") !== "TEXT") {
                  // 현재 라인이 TEXT가 아닌 경우 문의 후 삭제 결정
                  confirmRef.current = true;
                  // const result = await Confirm.ERROR("삭제", "다음의 내용을 삭제하시겠습니까?");
                  const result = false;
                  confirmRef.current = false;
                  if (!result) return false;
                }

                if (PREV_DOM?.getAttribute("data-type") === "TEXT") {
                  // 상단 라인이 TEXT 인 경우

                  if (NOW_DOM?.getAttribute("data-type") === "TEXT") {
                    const selection = window.getSelection() as Selection;
                    const position = fnGetPosition(NOW_DOM);

                    if (selection.isCollapsed && position === 0) {
                      // 텍스트 조합 이벤트
                      // ------------------------------------------------
                      let newHTML = "";

                      const checkRegex = new RegExp("<(h1|h2|h3)>", "g");
                      const replaceRegex = new RegExp(`<(h1|h2|h3)\>|\<\/(h1|h2|h3)\>`, "g");
                      const nextHTML = NOW_DOM.innerHTML.replace(replaceRegex, ""); // Heading 삭제

                      if (checkRegex.test(PREV_DOM.innerHTML)) {
                        // 상단의 텍스트의 Heading 속성일 경우

                        let flag = true;
                        ["h1", "h2", "h3"].forEach(tag => {
                          const regex = new RegExp(`\<${tag}\>`);
                          if (regex.test(PREV_DOM.innerHTML) && flag) {
                            const match = new RegExp(`(\<${tag}\>|\<\/${tag}\>)`, "g");
                            const tempHTML = PREV_DOM.innerHTML.replace(match, "");
                            newHTML = `<${tag}>${tempHTML + nextHTML}</${tag}>`;
                            flag = false;
                          }
                        });
                      } else {
                        // 상단의 텍스트의 Heading 속성이 아닌 경우
                        newHTML = PREV_DOM.innerHTML + nextHTML;
                      }

                      // 라인을 합칠때 같은 태그는 묶기 and 내용이 비어있는 태그 삭제
                      ["h1", "h2", "h3", "b", "i", "span", "strike"].forEach(tag => {
                        const regex = new RegExp(`(\<\/[${tag}]+\>)(\\<[${tag}]+>)`, "g");
                        const regex2 = new RegExp(`(\<[${tag}]+\>)(\<\/[${tag}]+>)`, "g");
                        newHTML = newHTML.replace(regex, "");
                        newHTML = newHTML.replace(regex2, "");
                      });

                      PREV_DOM.innerHTML = newHTML;
                      PREV_DOM.focus();
                      fnSetPosition(PREV_DOM, prevPosition);
                      handleRemoveLine(active);
                      setActive(p => p - 1);
                      // -------------------------------------
                      event.preventDefault();
                    }
                  } else {
                    PREV_DOM.focus();
                    fnSetPosition(PREV_DOM, prevPosition);
                    handleRemoveLine(active);
                    setActive(p => p - 1);
                  }
                } else {
                  // 상단 라인이 TEXT가 아닌 경우
                  handleRemoveLine(active);
                  setActive(p => p - 1);
                  event.preventDefault();
                }
              } else {
                // 상단 라인이 존재하지 않은 경우
                if (NOW_DOM?.getAttribute("data-type") !== "TEXT") {
                  // 현재 라인이 TEXT가 아닌 경우 문의 후 삭제 결정
                  confirmRef.current = true;
                  // const result = await Confirm.ERROR("삭제", "다음의 내용을 삭제하시겠습니까?");
                  const result = false;
                  confirmRef.current = false;
                  if (!result) return false;
                } else {
                  // 현재 라인이 TEXT인 경우
                  return false;
                }

                if (NEXT_DOM && NEXT_DOM?.getAttribute("data-type") === "TEXT") {
                  // 다음 라인이 존재하면서 TEXT 인 경우
                  NEXT_DOM.focus();
                  fnSetPosition(NEXT_DOM, 0);
                  handleRemoveLine(active);
                  event.preventDefault();
                } else {
                  // 다음 라인이 존재하지 않는 경우
                  handleRemoveLine(active);
                  setActive(p => p - 1);
                  event.preventDefault();
                }
              }
            }
            break;
          case 13:
            {
              // Enter
              const NOW_DOM = document.querySelector(`div[data-index="${active}"]`) as HTMLDivElement;
              if (NOW_DOM?.getAttribute("data-type") !== "TEXT") {
                handleAddLine({ type: "TEXT", idx: active + 1 });
                event.preventDefault();
              }
            }
            break;
          case 38:
            {
              // Up
              const NOW_DOM = document.querySelector(`div[data-index="${active}"]`) as HTMLDivElement;
              const PREV_DOM = document.querySelector(`div[data-index="${active - 1}"]`) as HTMLDivElement;
              // 현재 커서 포지션을 계산합니다.
              let position = fnGetPosition(NOW_DOM);
              if (PREV_DOM?.getAttribute("data-type") === "TEXT") {
                // 이전 라인의 타입이 TEXT인 경우
                // 예외 처리로 추출된 포지션값이 이전 라인의 최대 크기보다 클시 이전 라인의 마지막 크기로 합니다.
                if (position > (PREV_DOM?.textContent?.length as number)) position = PREV_DOM?.textContent?.length as number;
                PREV_DOM.focus();
                // 포커스 된 이전 라인에 포지션을 적용합니다.
                fnSetPosition(PREV_DOM, position);
                setActive(p => p - 1);
                event.preventDefault();
              } else if (PREV_DOM !== null) {
                // 이전 라인의 타입이 TEXT가 아닌 경우
                NOW_DOM.blur();
                setActive(p => p - 1);
                event.preventDefault();
              }
            }
            break;
          case 40:
            {
              // Down
              const NOW_DOM = document.querySelector(`div[data-index="${active}"]`) as HTMLDivElement;
              const NEXT_DOM = document.querySelector(`div[data-index="${active + 1}"]`) as HTMLDivElement;
              // 현재 커서 포지션을 계산합니다.
              let position = fnGetPosition(NOW_DOM);
              if (NEXT_DOM?.getAttribute("data-type") === "TEXT") {
                // 다음 라인의 타입이 TEXT인 경우
                // 예외 처리로 추출된 포지션값이 다음 라인의 최대 크기보다 클시 다음 라인의 마지막 크기로 합니다.
                if (position > (NEXT_DOM?.textContent?.length as number)) position = NEXT_DOM?.textContent?.length as number;
                NEXT_DOM.focus();
                // 포커스 된 다음 라인에 포지션을 적용합니다.
                fnSetPosition(NEXT_DOM, position);
                setActive(p => p + 1);
                event.preventDefault();
              } else if (NEXT_DOM !== null) {
                // 다음 라인이 존재할 때
                NOW_DOM.blur();
                setActive(p => p + 1);
                event.preventDefault();
              }
            }
            break;
          case 37:
            {
              // Left
              const NOW_DOM = document.querySelector(`div[data-index="${active}"]`) as HTMLDivElement;
              const PREV_DOM = document.querySelector(`div[data-index="${active - 1}"]`) as HTMLDivElement;
              // 현재 커서 포지션을 계산합니다.
              const position = fnGetPosition(NOW_DOM);
              if (position === 0 && NOW_DOM?.getAttribute("data-type") === "TEXT" && PREV_DOM?.getAttribute("data-type") === "TEXT") {
                // 현재 포지션이 0이고 ( 맨앞 ), 현재 돔과 이전 돔이 모두 TEXT 일때
                PREV_DOM.focus();
                // 이전돔의 마지막으로 커서 위치 변경
                fnSetPosition(PREV_DOM, PREV_DOM.textContent?.length as number);
                setActive(p => p - 1);
                event.preventDefault();
              } else if (NOW_DOM?.getAttribute("data-type") !== "TEXT" && PREV_DOM?.getAttribute("data-type") === "TEXT") {
                // 현재 돔이 TEXT가 아니고 이전 돔이 TEXT 일때
                PREV_DOM.focus();
                // 이전돔의 마지막으로 커서 위치 변경
                fnSetPosition(PREV_DOM, PREV_DOM.textContent?.length as number);
                setActive(p => p - 1);
                event.preventDefault();
              } else if (position === 0 && PREV_DOM !== null && PREV_DOM?.getAttribute("data-type") !== "TEXT") {
                // 이전돔이 존재하나 TEXT 형태가 아닐 때
                NOW_DOM.blur();
                setActive(p => p - 1);
                event.preventDefault();
              }
            }
            break;
          case 39:
            {
              // Right
              const NOW_DOM = document.querySelector(`div[data-index="${active}"]`) as HTMLDivElement;
              const NEXT_DOM = document.querySelector(`div[data-index="${active + 1}"]`) as HTMLDivElement;
              // 현재 커서 포지션을 계산합니다.
              const position = fnGetPosition(NOW_DOM);
              if (position === NOW_DOM.textContent?.length && NOW_DOM?.getAttribute("data-type") === "TEXT" && NEXT_DOM?.getAttribute("data-type") === "TEXT") {
                // 현재 포지션이 0이고 ( 맨앞 ), 현재 돔과 다음 돔이 모두 TEXT 일때
                NEXT_DOM.focus();
                // 다음돔의 첫번째로 커서 위치 변경
                fnSetPosition(NEXT_DOM, 0);
                setActive(p => p + 1);
                event.preventDefault();
              } else if (NOW_DOM?.getAttribute("data-type") !== "TEXT" && NEXT_DOM?.getAttribute("data-type") === "TEXT") {
                // 현재 돔이 TEXT가 아니고 다음 돔이 TEXT 일때
                NEXT_DOM.focus();
                // 다음돔의 첫번째로 커서 위치 변경
                fnSetPosition(NEXT_DOM, 0);
                setActive(p => p + 1);
                event.preventDefault();
              } else if (position === NOW_DOM.textContent?.length && NEXT_DOM !== null && NEXT_DOM?.getAttribute("data-type") !== "TEXT") {
                // 다음돔이 존재하나 TEXT 형태가 아닐 때
                NOW_DOM.blur();
                setActive(p => p + 1);
                event.preventDefault();
              }
            }
            break;
        }
      }
    } catch (error) {
      event.preventDefault();
    }
  };

  const handleKeyEvent = (event: React.KeyboardEvent<HTMLDivElement>, index: number) => {
    try {
      if (open) return false;
      // console.log(`Text: ${event.keyCode}`);
      switch (event.keyCode) {
        case 8:
          {
            // Backspace
            const NOW_DOM = document.querySelector(`div[data-index="${active}"]`) as HTMLDivElement;
            // 현재 커서 포지션을 계산합니다.
            const position = fnGetPosition(NOW_DOM);
            // 커서의 위치가 0이 아닐 때 글로벌 키보드 이벤트를 막기
            if (position !== 0) event.stopPropagation();
          }
          break;
        case 9: {
          // Tab
          const NOW_DOM = document.querySelector(`div[data-index="${active}"]`) as HTMLDivElement;
          NOW_DOM.innerText += "\t";
          fnSetPosition(NOW_DOM, NOW_DOM.textContent?.length as number);
          event.preventDefault();
          break;
        }
        case 13:
          {
            // Enter
            const selection = window.getSelection() as Selection;
            const NOW_DOM = document.querySelector(`div[data-index="${active}"]`) as HTMLDivElement;

            // 영역 선택 중일때 영역부분을 삭제하니다.
            if (!selection.isCollapsed) selection.deleteFromDocument();

            // 현재 커서 포지션을 계산합니다.
            const position = fnGetPosition(NOW_DOM);
            // 현재 포지션을 기준으로 뒤로 HTML을 추출합니다.
            let { nextHTML } = fnCutHTML(NOW_DOM, position);
            setLines(prev => {
              prev.splice(index + 1, 0, { _id: uuid(), type: "TEXT", html: nextHTML });
              return [...prev];
            });

            // 반복을 하면서 무의미하게 생성된 태그를 제거합니다.
            while (/(\<[a-zA-Z0-9]+[\>])(\<\/[a-zA-Z0-9]+\>)/g.test(nextHTML)) {
              nextHTML = nextHTML.replace(/(\<[a-zA-Z0-9]+[\>])(\<\/[a-zA-Z0-9]+\>)/g, "");
            }

            // 다음 HTML의 내용이 빈내용이 아니라면
            if (nextHTML !== "") {
              // 해더를 판단하고 생성해줍니다.
              ["h1", "h2", "h3"].forEach(tag => {
                const regex = new RegExp(`\<${tag}\>`);
                if (regex.test(NOW_DOM.innerHTML)) nextHTML = `<${tag}>${nextHTML}</${tag}>`;
              });
            }

            setActive(p => p + 1);
            // 내용을 변경해줍니다.
            setTimeout(() => commendRef.current[active + 1].modify(nextHTML));
            event.preventDefault();
            event.stopPropagation();
          }
          break;
      }
    } catch (error) {
      event.preventDefault();
    }
  };

  const handleToggleImageType = () => {
    setLines(prev => {
      return [
        ...prev.map((line, index) => {
          if (index === active) line.view === "COLLAGE" ? (line.view = "SLIDE") : (line.view = "COLLAGE");
          return line;
        })
      ];
    });
  };

  const handleDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    event.dataTransfer.effectAllowed = "move";
    event.stopPropagation();
  };

  const handleDragEnd = (event: React.DragEvent<HTMLDivElement>) => {
    const element = event.currentTarget as HTMLDivElement;
    element.style.backgroundColor = "transparent";
  };

  const handleDrag = (event: React.DragEvent<HTMLDivElement>) => {
    const selectedItem = event.currentTarget as HTMLDivElement;
    const list = selectedItem.parentNode as HTMLDivElement;
    const x = event.clientX;
    const y = event.clientY;

    selectedItem.classList.add("drag");
    let swapItem = document.elementFromPoint(x, y) === null ? selectedItem : (document.elementFromPoint(x, y) as HTMLDivElement);

    if (list === swapItem.parentNode && swapItem.getAttribute("data-line")) {
      swapItem = swapItem !== selectedItem.nextSibling ? swapItem : (swapItem.nextSibling as HTMLDivElement);
      list.insertBefore(selectedItem, swapItem);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    const list: number[] = [];
    (event.currentTarget as HTMLDivElement).classList.remove("drag");
    for (const element of (event.currentTarget as HTMLDivElement).parentElement?.childNodes as NodeListOf<HTMLDivElement>) {
      if (element.getAttribute("data-line")) {
        list.push(Number(element.getAttribute("data-num")));
      }
    }

    // 위치변경에 따른 데이터 변경
    setLines(prev => {
      prev = list.map(num => prev[num]);
      return prev;
    });

    event.dataTransfer.dropEffect = "move";
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDragCommon = (event: React.DragEvent<HTMLDivElement>) => {
    event.dataTransfer.dropEffect = "move";
    event.preventDefault();
    event.stopPropagation();
  };

  console.log(lines, "lines");

  return (
    <Wrap fullWidth={options.fullWidth!}>
      <Toolbar />
      <Controller line={lines[active]} onToggleImageType={handleToggleImageType} />

      <Editor ref={editorRef}>
        {lines.map((line, index) => {
          if (line.type === "TEXT")
            return (
              <Text
                key={line._id}
                ref={ref => (commendRef.current[index] = ref as ICommend)}
                index={index}
                align="LEFT"
                line={line}
                onActive={idx => setActive(idx)}
                onChange={handleChangeHtml}
                onKeyEvent={handleKeyEvent}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                onDrag={handleDrag}
                onDrop={handleDrop}
                onDropCommon={handleDragCommon}
              />
            );
        })}
        <WriteZone
          onClick={() => {
            handleAddLine({ type: "TEXT", idx: lines.length });
          }}
        >
          aaaa
        </WriteZone>
      </Editor>
    </Wrap>
  );
};

export default React.memo(EditorComponent);

export const EditorParser = (lines: IEditorLine[]) => {
  return "";
};

const Wrap = styled.div<{ fullWidth: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid ${grey.primary};
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;

const Toolbar = styled.div`
  position: relative;
  display: flex;
  height: 55px;
  color: ${blue.primary};
  border-bottom: 1px solid ${blue.primary};
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 55px;
  height: 100%;
  cursor: pointer;

  span {
    height: 23px;
    font-size: 12.5px;
  }

  &:hover {
    color: ${blue.primary};
  }
`;

const Editor = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px 0 0;
  overflow-y: scroll;
`;

const WriteZone = styled.div`
  height: 100%;
  cursor: text;
`;
