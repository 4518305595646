import { gql } from "apollo-boost";

export const ME = gql`
  query me {
    me {
      email
      nickName
    }
  }
`;

export const LOGIN = gql`
  mutation login($email: String, $password: String!) {
    login(email: $email, password: $password)
  }
`;

export const CREATE_USER = gql`
  mutation createUser($email: String, $nickName: String!, $password: String!) {
    createUser(email: $email, nickName: $nickName, password: $password) {
      _id
      email
      nickName
    }
  }
`;

export const CHECK_VALIDATION = gql`
  mutation checkValidation($email: String, $nickName: String, $phone: String) {
    checkValidation(email: $email, nickName: $nickName, phone: $phone)
  }
`;
