import React, { useEffect } from "react";
import withLayout from "layouts/DefaultLayout";
import styled from "styles/theme";

import Editor from "@toast-ui/editor";
import "codemirror/lib/codemirror.css"; // Editor's Dependency Style
import "@toast-ui/editor/dist/toastui-editor.css"; // Editor's Style

const WriteView: React.FC<RouteComponentProps> = () => {
  useEffect(() => {
    const editorWrap = document.querySelector("#editor");

    if (editorWrap) {
      const editor = new Editor({
        el: editorWrap as HTMLDivElement,
        height: "auto",
        minHeight: "500px",
        initialEditType: "wysiwyg",
        previewStyle: "vertical"
      });

      editor.getHtml();
    }

    const editorWrap2 = document.querySelector("#editor2");

    if (editorWrap2) {
      const editor2 = new Editor({
        el: editorWrap2 as HTMLDivElement,
        height: "500px",
        initialEditType: "wysiwyg",
        previewStyle: "vertical"
      });

      editor2.getHtml();
    }

    const editorWrap3 = document.querySelector("#editor3");

    if (editorWrap3) {
      const editor3 = new Editor({
        el: editorWrap3 as HTMLDivElement,
        height: "500px",
        initialEditType: "wysiwyg",
        previewStyle: "vertical"
      });

      editor3.getHtml();
    }
  }, []);

  return (
    <Wrap>
      <div id="editor"></div>
      <div id="editor2"></div>
      <div id="editor3"></div>
    </Wrap>
  );
};

export default withLayout(WriteView);

const Wrap = styled.div``;
