import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import cookie from "js-cookie";

import { ROOT } from "api/Client";

import { ThemeProvider } from "emotion-theming";

import "locales";
import { locales } from "locales/index";

import { theme as themeMap, themeTypes } from "styles/theme";

import Home from "views/Home";
import Login from "views/Login";
import SignUp from "views/Login/SignUp";
import Guide from "views/Guide";
import Todo from "views/Todo";
import TodoArchive from "views/Todo/Archive";
import Write from "views/Board/Write";
import Editor from "views/Board/Editor";

const getLocale = cookie.get("locale");

export const urlLangCheck = () => {
  const { location, history } = window;
  const { origin, pathname } = location;
  if (!pathname.includes(`${getLocale}`)) {
    history.replaceState(null, "", `${origin}/${getLocale}${pathname}`);
  }
};

const pathLang = (url: string[]) => {
  const result = url.reduce((acc: string[], cur) => {
    acc = [...acc];
    // acc.push(cur);
    locales.forEach(item => {
      acc.push(`/${item}${cur}`);
    });
    return acc;
  }, []);

  return result;
};

const privateRules: RouteRules[] = [
  {
    exact: true,
    path: pathLang(["/", "/home"]),
    component: Home
  },
  {
    exact: true,
    path: pathLang(["/guide", "/guide123"]),
    component: Guide
  },
  {
    exact: true,
    path: pathLang(["/todo"]),
    component: Todo
  },
  {
    exact: true,
    path: pathLang(["/todo/archive"]),
    component: TodoArchive
  },
  {
    exact: true,
    path: pathLang(["/chat"]),
    component: Todo
  },
  {
    exact: true,
    path: pathLang(["/board/write"]),
    component: Write
  },
  {
    exact: true,
    path: pathLang(["/board/Editor"]),
    component: Editor
  }
];

const publicRules: RouteRules[] = [];

const notLoginRules: RouteRules[] = [
  {
    exact: true,
    path: pathLang(["/login"]),
    component: Login
  },
  {
    exact: true,
    path: pathLang(["/signup"]),
    component: SignUp
  }
];

// const PrivateRoute: React.FC = () => {
//   return (
//     <Switch>
//       {privateRules.map((item, index) => (
//         <Route exact {...item} key={index} />
//       ))}
//       <Redirect to={`/${getLocale}`} />
//     </Switch>
//   );
// };

// const PublicRoute: React.FC = () => {
//   return (
//     <Switch>
//       {publicRules.map((item, index) => (
//         <Route exact {...item} key={index} />
//       ))}
//       <Redirect to={""} />
//     </Switch>
//   );
// }

const RouteSwitch: React.FC<RouteSwitch> = ({ isLoggedIn }) => {
  return (
    <Switch>
      {isLoggedIn && privateRules.map((item, index) => <Route {...item} key={index} />)}
      {!isLoggedIn && notLoginRules.map((item, index) => <Route {...item} key={index} />)}
      {publicRules.map((item, index) => (
        <Route {...item} key={index} />
      ))}
      <Redirect to={`/${getLocale}${isLoggedIn ? "" : "/login"}`} />
    </Switch>
  );
};

const Root = () => {
  const { data: clientData, loading: clientLoading, error } = useQuery(ROOT);

  const { theme, isLoggedIn } = clientData;

  document.body.id = theme;

  if (error) return <div>{error.message}</div>;
  if (clientLoading) return <div>Root Loading</div>;

  return (
    <>
      <Router>
        {/* <ThemeProvider theme={themeMap[theme as themeTypes]}>{!isLoggedIn ? <PrivateRoute /> : <PublicRoute />}</ThemeProvider> */}
        <ThemeProvider theme={themeMap[theme as themeTypes]}>
          <RouteSwitch isLoggedIn={isLoggedIn} />
        </ThemeProvider>
      </Router>
    </>
  );
};

export { publicRules, notLoginRules };
export default Root;
