import React from "react";
import withLayout from "layouts/DefaultLayout";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { Typography, Skeleton, List } from "antd";

import { Item as TodoItem } from "components/Todo";

import { useQuery, useMutation } from "@apollo/react-hooks";
import { TODOS, UPDATE_TODO, DELETE_TODO } from "api/Todo";

const TodoView: React.FC<RouteComponentProps> = ({ user }) => {
  const { t } = useTranslation("Test");
  const { Title } = Typography;

  const { data: todoData, loading: todoLoading } = useQuery(TODOS, {
    variables: {
      isArchive: true
    },
    onCompleted: data => {
      console.log(data, "data");
    },
    onError: err => {
      console.log(err, "err");
    }
  });

  const [UpdateTodoMutation] = useMutation(UPDATE_TODO, {
    refetchQueries: [
      { query: TODOS },
      {
        query: TODOS,
        variables: {
          isArchive: true
        }
      }
    ],
    onError: err => {
      console.log(err, "err");
    }
  });

  const [DeleteTodoMutation] = useMutation(DELETE_TODO, {
    refetchQueries: [
      {
        query: TODOS,
        variables: {
          isArchive: true
        }
      }
    ],
    onError: err => {
      console.log(err, "err");
    }
  });

  const handleTodoUpdate = (props: ITodoItem) => {
    UpdateTodoMutation({
      variables: props
    });
  };

  const handleTodoDelete = (_id: string) => {
    DeleteTodoMutation({
      variables: {
        _id
      }
    });
  };

  return (
    <Wrap>
      <Title level={3}>{user.nickName}의 보관함</Title>
      <List
        bordered
        loading={todoLoading}
        dataSource={todoData?.todos as ITodoItem[]}
        renderItem={item => (
          <List.Item>
            <Skeleton title={false} paragraph={{ rows: 1, width: "100%" }} loading={todoLoading} active>
              <TodoItem {...item} callUpdate={handleTodoUpdate} callDelete={handleTodoDelete} />
            </Skeleton>
          </List.Item>
        )}
      />
    </Wrap>
  );
};

export default withLayout(TodoView);

const Wrap = styled.div``;
