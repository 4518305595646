// 포지션 추출
export const fnGetPosition = (dom: HTMLDivElement): number => {
  try {
    const selection = window.getSelection() as Selection;
    const range = selection.getRangeAt(0);

    let childNodes = [...dom.childNodes];

    // 마지막까지 반복을 하면서 HTML을 분해합니다. 분해되어 배열형태로 childNodes에 담습니다.
    while (childNodes.some(child => child.nodeName !== "#text")) {
      childNodes = [
        ...childNodes.reduce<ChildNode[]>((acc, child) => {
          if (child.nodeName !== "#text") {
            acc = [...acc, ...child.childNodes];
          } else {
            acc.push(child);
          }
          return acc;
        }, [])
      ];
    }

    let flag = false; // 종료됨을 체크하는 flag
    let position = 0; // 현재 포지션 기본값 0;

    // 반복을 하며 커서의 위치를 추출하고 추출되면 flag를 바꾸어 더이상 변경되는것을 방지합니다.
    childNodes.forEach(child => {
      if (child === range.startContainer) {
        flag = true;
        position += range.startOffset;
      }

      if (!flag) {
        position += child.nodeValue?.length as number;
      }
    });

    // 추출된 position 반환
    return position;
  } catch (error) {
    console.log(error);
    return 0;
  }
};

// HTML 자르기
export const fnCutHTML = (dom: HTMLDivElement, position: number): { prevHTML: string; nextHTML: string } => {
  const range = new Range();
  // 전달 받는 포지션의 값으로 2개의 container를 만듭니다.
  const { container: startContainer, offset: startOffset } = fnCalcPosition(dom, position);
  const { container: endContainer, offset: endOffset } = fnCalcPosition(dom, dom.innerText.length);
  range.setStart(startContainer as ChildNode, startOffset as number);
  range.setEnd(endContainer as ChildNode, endOffset as number);
  // 임시적인 DIV 돔을 생성 ( 뒤의 값을 넣습니다. )
  const tempDOM = document.createElement("div");
  const fragment = range.extractContents();
  if (endContainer?.parentElement?.nodeName !== "DIV") {
    const tag = document.createElement(endContainer?.parentElement?.nodeName as string);
    tag.appendChild(fragment);
    tempDOM.append(tag);
  } else {
    tempDOM.append(fragment);
  }
  // 앞의 container에서 뒤의 값 제거
  range.deleteContents();
  // 앞의 내용과 뒤의 내용의 HTML을 각각 분리해서 반환합니다.
  const prevHTML = dom.innerHTML;
  const nextHTML = tempDOM.innerHTML;

  return { prevHTML, nextHTML };
};

// 포지션을 기준으로 자르기
export const fnCalcPosition = (dom: HTMLDivElement, position: number): { container: ChildNode; offset: number } => {
  let childNodes = [...dom.childNodes];

  // 마지막까지 반복을 하면서 HTML을 분해합니다. 분해되어 배열형태로 childNodes에 담습니다.
  while (childNodes.some(child => child.nodeName !== "#text")) {
    childNodes = [
      ...childNodes.reduce<ChildNode[]>((acc, child) => {
        if (child.nodeName !== "#text") {
          acc = [...acc, ...child.childNodes];
        } else {
          acc.push(child);
        }
        return acc;
      }, [])
    ];
  }

  let _position = position; // 기본 포지션은 현재값

  const { container, offset } = childNodes.reduce<{ container: ChildNode; offset: number }>(
    (acc, curr) => {
      if (_position >= 0) {
        // 현재 position이 0이상일 때 값 매칭
        acc.offset = _position; // 현재 position;
        acc.container = curr; // n 번째 ChildNode
      }

      _position -= curr.textContent?.length as number; // 현재 컨테이너의 글자만큼 차감

      return acc;
    },
    { container: document.createElement("DIV").firstChild as ChildNode, offset: 0 }
  );

  return { container: container || dom, offset };
};

// 지정한 돔에 postion 적용
export const fnSetPosition = (dom: HTMLDivElement, offset: number) => {
  let flag = false;
  let childNodes = [...dom.childNodes];

  // 마지막까지 반복을 하면서 HTML을 분해합니다. 분해되어 배열형태로 childNodes에 담습니다.
  while (childNodes.some(child => child.nodeName !== "#text")) {
    childNodes = [
      ...childNodes.reduce<ChildNode[]>((acc, child) => {
        if (child.nodeName !== "#text") {
          acc = [...acc, ...child.childNodes];
        } else {
          acc.push(child);
        }
        return acc;
      }, [])
    ];
  }

  // 반복하며 range 위치를 잡고 넣어줍니다.
  childNodes.forEach(child => {
    if (offset > (child.textContent?.length as number)) {
      offset -= child.textContent?.length as number;
    } else if (!flag) {
      flag = true;
      const selection = window.getSelection() as Selection;
      const range = document.createRange();
      range.setStart(child, offset);
      range.collapse(true);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  });
};
