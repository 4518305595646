import React from "react";
import Frame, { FrameContextConsumer } from "react-frame-component";
import styled from "styled-components";
import {
  BarsOutlined,
  BoldOutlined,
  ItalicOutlined,
  UnderlineOutlined,
  StrikethroughOutlined,
  AlignLeftOutlined,
  AlignCenterOutlined,
  AlignRightOutlined,
  FileImageOutlined
} from "@ant-design/icons";

const ItemCSS = {
  display: "flex",
  minWidth: "40px",
  height: "40px",
  padding: "0 3px",
  alignItems: "center",
  justifyContent: "center",
  color: "#999",
  fontSize: "8px",
  cursor: "pointer"
};

const TextTypeComponent = () => {
  const handleClick = (event: React.MouseEvent<HTMLDivElement>, type: string) => {
    switch (type) {
      case "H1":
        document.execCommand("formatBlock", false, "h1");
        break;
      case "H2":
        document.execCommand("formatBlock", false, "h2");
        break;
      case "H3":
        document.execCommand("formatBlock", false, "h3");
        break;
      case "Bold":
        document.execCommand("Bold", false);
        break;
      case "Italic":
        document.execCommand("Italic", false);
        break;
      case "StrikeThrough":
        document.execCommand("StrikeThrough", false);
        break;
      case "Underline":
        document.execCommand("Underline", false);
        break;
      case "Left":
        document.execCommand("justifyleft", false);
        break;
      case "Center":
        document.execCommand("justifycenter", false);
        break;
      case "Right":
        document.execCommand("justifyright", false);
        break;
    }
    event.preventDefault();
    event.stopPropagation();
    window.focus();
  };

  return (
    <React.Fragment>
      <div style={ItemCSS} onClick={event => handleClick(event, "H1")}>
        <BarsOutlined />
      </div>
      <div style={ItemCSS} onClick={event => handleClick(event, "H2")}>
        <BarsOutlined />
      </div>
      <div style={ItemCSS} onClick={event => handleClick(event, "H3")}>
        <BarsOutlined />
      </div>
      <div style={ItemCSS} onClick={event => handleClick(event, "Bold")}>
        <BoldOutlined />
      </div>
      <div style={ItemCSS} onClick={event => handleClick(event, "Italic")}>
        <ItalicOutlined />
      </div>
      <div style={ItemCSS} onClick={event => handleClick(event, "StrikeThrough")}>
        <StrikethroughOutlined />
      </div>
      <div style={ItemCSS} onClick={event => handleClick(event, "Underline")}>
        <UnderlineOutlined />
      </div>
      <div style={ItemCSS} onClick={event => handleClick(event, "Left")}>
        <AlignLeftOutlined />
      </div>
      <div style={ItemCSS} onClick={event => handleClick(event, "Center")}>
        <AlignCenterOutlined />
      </div>
      <div style={ItemCSS} onClick={event => handleClick(event, "Right")}>
        <AlignRightOutlined />
      </div>
    </React.Fragment>
  );
};

const ImageTypeComponent: React.FC<{ onToggleImageType: (event: React.MouseEvent<HTMLDivElement>) => void }> = ({ onToggleImageType }) => {
  return (
    <React.Fragment>
      <div style={ItemCSS} onClick={onToggleImageType}>
        <FileImageOutlined />
      </div>
    </React.Fragment>
  );
};

const VideoTypeComponent = () => {
  return <React.Fragment />;
};

const AudioTypeComponent = () => {
  return <React.Fragment />;
};

const LocationTypeComponent = () => {
  return <React.Fragment />;
};

const ScheduleTypeComponent = () => {
  return <React.Fragment />;
};

const VoteTypeComponent = () => {
  return <React.Fragment />;
};

const EditorTextToolbarComonent: React.FC<{ line: IEditorLine; onToggleImageType: (event: React.MouseEvent<HTMLDivElement>) => void }> = ({ line, onToggleImageType }) => {
  let type = line?.type as string;
  if (line?.type === "FILE") type = line.files![0].type;

  return (
    <Wrap>
      <FrameContextConsumer>
        {({ document }) => {
          document.body.style.margin = 0;
          const ListCss = { display: "flex", height: "39px", borderBottom: "1px solid #E5E9F2", backgroundColor: "#fff" };
          return (
            <div style={ListCss}>
              {(type === "TEXT" || type === undefined) && <TextTypeComponent />}
              {type === "IMAGE" && <ImageTypeComponent onToggleImageType={onToggleImageType} />}
              {type === "VIDEO" && <VideoTypeComponent />}
              {type === "AUDIO" && <AudioTypeComponent />}
              {type === "LOCATUON" && <LocationTypeComponent />}
              {type === "SCHEDULE" && <ScheduleTypeComponent />}
              {type === "VOTE" && <VoteTypeComponent />}
            </div>
          );
        }}
      </FrameContextConsumer>
    </Wrap>
  );
};

export default EditorTextToolbarComonent;
const Wrap = styled(Frame)`
  height: 40px;
`;

// const List = styled.div`
//   display: flex;
//   border: 1px solid rgba(0, 0, 0, 0.12);
//   box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
//   background-color: #fff;
// `;

// const Item = styled.div`
//   display: flex;
//   flex-basis: 35px;
//   width: 35px;
//   height: 35px;
//   align-items: center;
//   justify-content: center;
//   cursor: pointer;

//   &:hover {
//     background-color: ${Colors.NEUTRAL05};
//   }
// `;
