import React from "react";
import { Link } from "react-router-dom";
import { locales } from "locales";

const LocalLinkComponent: React.FC<ILocalLink> = ({ to, children, ...props }) => {
  const { href } = window.location;

  const localization = locales.filter(item => href.toLowerCase().includes(item.toLowerCase())).shift();
  return (
    <Link to={`/${localization}${to}`} {...props}>
      {children}
    </Link>
  );
};

LocalLinkComponent.defaultProps = {};

export default LocalLinkComponent;
