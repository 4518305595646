import { gql } from "apollo-boost";

export const TODOS = gql`
  query todos($isArchive: Boolean) {
    todos(isArchive: $isArchive) {
      _id
      isArchive
      isComplete
      content
      createdAt
      completeAt
    }
  }
`;

export const CREATE_TODO = gql`
  mutation createTodo($content: String) {
    createTodo(content: $content) {
      _id
      content
      createdAt
    }
  }
`;

export const UPDATE_TODO = gql`
  mutation updateTodo($_id: ID!, $isArchive: Boolean, $isComplete: Boolean, $content: String) {
    updateTodo(_id: $_id, isArchive: $isArchive, isComplete: $isComplete, content: $content) {
      _id
      isArchive
      isComplete
      content
    }
  }
`;

export const DELETE_TODO = gql`
  mutation deleteTodo($_id: ID!) {
    deleteTodo(_id: $_id) {
      _id
      content
    }
  }
`;
