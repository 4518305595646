import { ApolloClient, split, InMemoryCache, defaultDataIdFromObject } from "apollo-boost";
import { createHttpLink } from "apollo-link-http";
import { WebSocketLink } from "apollo-link-ws";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { getMainDefinition } from "apollo-utilities";
import cookie from "js-cookie";
import { defaults, resolvers } from "./LocalState";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_HTTP_ENDPOINT as string
});

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_GRAPHQL_WS_ENDPOINT as string,
  options: { reconnect: true }
});

const authLink = setContext(async (_, { headers }) => {
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${cookie.get("token")}`
    }
  };
});

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    // console.log(graphQLErrors, "graphQLErrors");
    for (const err of graphQLErrors) {
      // console.log(err, "err");
      switch (err.message.toLocaleUpperCase()) {
        case "FORBIDDEN":
        case "UNAUTHORIZED": {
          // error code is set to UNAUTHENTICATED
          // when AuthenticationError thrown in resolver
          // modify the operation context with a new token

          // const token = auth.getIdToken();
          // const oldHeaders = operation.getContext().headers;

          // operation.setContext({
          //   headers: {
          //     ...oldHeaders,
          //     authorization: token
          //   }
          // });
          // retry the request, returning the new observable
          return forward(operation);
        }
      }
    }
  }
  if (networkError) {
    // console.log(`[Network error]: ${networkError}`);
    // if you would also like to retry automatically on
    // network errors, we recommend that you use
    // apollo-link-retry
  }
});

const httpAuthLink = authLink.concat(errorLink).concat(httpLink);

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === "OperationDefinition" && definition.operation === "subscription";
  },
  wsLink,
  httpAuthLink
);

const cache = new InMemoryCache({
  dataIdFromObject: object => {
    return defaultDataIdFromObject(object);
  }
});

cache.writeData({
  data: defaults
});

export default new ApolloClient({
  link,
  cache,
  resolvers
});
