import React, { forwardRef, useRef, useEffect, useImperativeHandle } from "react";
import styled from "styled-components";
import { grey } from "@ant-design/colors";

interface IProps {
  index: number;
  line: IEditorLine;
  align: EditorAligns;
  onActive(index: number): void;
  onChange: (index: number, line: any) => void;
  onKeyEvent: (event: React.KeyboardEvent<HTMLDivElement>, idx: number) => void;
  onDragStart: (event: React.DragEvent<HTMLDivElement>) => void;
  onDragEnd: (event: React.DragEvent<HTMLDivElement>) => void;
  onDrag: (event: React.DragEvent<HTMLDivElement>) => void;
  onDrop: (event: React.DragEvent<HTMLDivElement>) => void;
  onDropCommon: (event: React.DragEvent<HTMLDivElement>) => void;
}

interface ICommend {
  modify: (html: string) => void;
}

const TextComponent = forwardRef<ICommend, IProps>(({ index, align, onActive, onChange, onKeyEvent, onDragStart, onDragEnd, onDrag, onDrop, onDropCommon }, ref) => {
  const inputRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, []);

  useImperativeHandle(ref, () => ({
    modify: (html: string) => ((inputRef.current as HTMLDivElement).innerHTML = html)
  }));

  const handleInput = (event: React.FormEvent<HTMLDivElement>) => {
    onChange(index, event.currentTarget.innerHTML);
  };

  return (
    <Wrap data-line data-num={index} draggable onDragStart={onDragStart} onDragEnd={onDragEnd} onDrag={onDrag} onDrop={onDrop} onDragEnter={onDropCommon} onDragOver={onDropCommon}>
      <Line
        ref={inputRef}
        data-type="TEXT"
        data-index={index}
        align={align}
        contentEditable
        spellCheck
        onInput={handleInput}
        onKeyDown={event => onKeyEvent(event, index)}
        onClick={() => onActive(index)}
        placeholder="입력해주세요."
      />
    </Wrap>
  );
});

export default TextComponent;

const Wrap = styled.div`
  width: 100%;
`;

const Line = styled.div<{ align: EditorAligns; placeholder?: string }>`
  position: relative;
  display: block;
  width: 940px;
  margin: 0 auto;
  font-size: 14px;
  ${p => p.align === "LEFT" && "text-align: left;"};
  ${p => p.align === "CENTER" && "text-align: center;"};
  ${p => p.align === "RIGHT" && "text-align: right;"};
  white-space: pre-wrap;
  word-break: break-word;

  &.selected::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.2);
  }

  &:focus:empty::after {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 940px;
    height: 100%;
    content: "${p => p.placeholder}";
    color: ${grey.primary};
    pointer-events: none;
  }

  b {
    font-weight: bold;
  }

  i {
    font-style: italic;
  }
`;
