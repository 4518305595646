import React, { useState } from "react";
import withLayout from "layouts/EmptyLayout";
import styled from "@emotion/styled";
// import { useTranslation } from "react-i18next";
import { Typography, Form, Input, Button, message, Modal } from "antd";
import { Rule, FormItemProps } from "antd/lib/form";
import { CheckCircleTwoTone } from "@ant-design/icons";

import { LocalLink } from "styles";

import { useMutation } from "@apollo/react-hooks";
import { CREATE_USER, CHECK_VALIDATION } from "api/User";

const SignUpView: React.FC<RouteComponentProps> = () => {
  // const { t } = useTranslation("Test");
  const { Title } = Typography;

  const [validation, setValidation] = useState({ email: null, nickName: null });
  const [curValidation, setCurValidation] = useState<string>("");
  const [confirm, setConfirm] = useState<boolean>(false);

  const [CreateUserMutation] = useMutation(CREATE_USER, {
    onCompleted: data => {
      if (data) {
        console.log(data, "data");
      }
      setConfirm(true);
    }
  });

  const [CheckValidationMutation] = useMutation(CHECK_VALIDATION, {
    onCompleted: data => {
      if (data) {
        setValidation({
          ...validation,
          [curValidation]: !data.checkValidation
        });
      }
    }
  });

  const handleCheck = (e: React.FocusEvent<HTMLInputElement>) => {
    const { currentTarget } = e;

    if (currentTarget.value.length === 0) return;

    const value = currentTarget.id.split("_")[1];

    setCurValidation(value);

    CheckValidationMutation({
      variables: {
        [value]: currentTarget.value
      }
    });
  };

  const onFinish = (values: any) => {
    console.log("Success:", values);

    if (validation.email && validation.nickName) {
      CreateUserMutation({
        variables: {
          email: values.email,
          password: values.password,
          nickName: values.nickName
        }
      });
    } else {
      message.error("입력값을 제대로 입력해주세요.");
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
    message.error("입력값을 제대로 입력해주세요.");
  };

  const rules: rulesBaseType<Rule> = {
    email: [
      {
        type: "email",
        message: "이메일형식이 아닙니다."
      },
      { required: true, message: "로그인에 사용할 이메일을 입력해주세요." }
    ],
    password: [{ required: true, message: "패스워드를 입력해주세요." }],
    passwordConfirm: [
      { required: true, message: "패스워드를 한번 더 입력 해주세요." },
      ({ getFieldValue }) => ({
        validator(rule, value) {
          if (!value || getFieldValue("password") === value) {
            return Promise.resolve();
          }
          return Promise.reject("패스워드 입력값이 다릅니다.");
        }
      })
    ],
    nickName: [{ required: true, message: "닉네임을 입력해주세요." }]
  };

  const formItems: FormItemProps[] = [
    {
      label: "이메일",
      name: "email",
      hasFeedback: true,
      validateStatus: validation.email === null ? "" : validation.email ? "success" : "error",
      help: validation.email === null ? null : !validation.email && "이미 사용중입니다.",
      children: <Input placeholder={"example@example.com"} size={"large"} onBlur={handleCheck} />
    },
    {
      label: "패스워드",
      name: "password",
      children: <Input.Password size={"large"} />
    },
    {
      label: "패스워드 확인",
      name: "passwordConfirm",
      dependencies: ["password"],
      children: <Input.Password size={"large"} />
    },
    {
      label: "닉네임",
      name: "nickName",
      hasFeedback: true,
      validateStatus: validation.nickName === null ? "" : validation.nickName ? "success" : "error",
      help: validation.nickName === null ? null : !validation.nickName && "이미 사용중입니다.",
      children: <Input placeholder={"example@example.com"} size={"large"} onBlur={handleCheck} />
    },
    {
      className: "btn-area",
      children: (
        <Button type="primary" htmlType="submit" size={"large"} block>
          화원가입
        </Button>
      )
    }
  ];

  return (
    <Wrap>
      <Title level={2} className={"title"}>
        회원가입
      </Title>
      <Form layout={"vertical"} name="sign-up" onFinish={onFinish} onFinishFailed={onFinishFailed}>
        {formItems.map((item, index) => (
          <Form.Item {...item} rules={rules[item.name as string]} key={index} />
        ))}
      </Form>
      <Modal visible={confirm} closable={false} maskClosable={false} footer={null}>
        <Complete>
          <CheckCircleTwoTone style={{ fontSize: "50px" }} twoToneColor={"#73d13d"} />
          <Title level={3}>가입 완료</Title>
          <Button type={"primary"}>
            <LocalLink to={"/login"}>로그인 하러 가기</LocalLink>
          </Button>
        </Complete>
      </Modal>
    </Wrap>
  );
};

export default withLayout(SignUpView);

// const SubmitButton: React.FC = ({ disabled }) => {
//   <Button type="primary" htmlType="submit" size={"large"} block disabled>
//     화원가입
//   </Button>;
// };

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 80vh;

  .title {
    text-align: center;
  }

  #sign-up {
    margin: 0 auto;
    width: 100%;
    max-width: 360px;
  }

  .btn-area {
    margin-top: 50px;
    text-align: center;
  }
`;

const Complete = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & h3 {
    margin-top: 20px;
  }
`;
