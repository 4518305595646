import React from "react";
import withLayout from "layouts/DefaultLayout";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { changeLang } from "locales";
import { Button } from "antd";
import { LocalLink, Title } from "styles";

const HomeView: React.FC<RouteComponentProps> = ({ user, ...props }) => {
  const { t } = useTranslation("Test");

  console.log(user, "user");

  console.log(props, "props");

  return (
    <Wrap>
      {t("test")}
      {user.nickName && <div>{user.nickName}</div>}
      <div>
        <Button type={"primary"} onClick={() => changeLang("ko-KR")}>
          한국어
        </Button>
        <button type="button" onClick={() => changeLang("en-US")}>
          영어
        </button>
      </div>
      <Title shape="H1">ddsadasdas</Title>
      <Title shape="H2">ddsadasdas</Title>
      <Title shape="H3">ddsadasdas</Title>
      <Title shape="H4">ddsadasdas</Title>
      <Title shape="H5">ddsadasdas</Title>
      <LocalLink to={"/login"}>로그인</LocalLink>
    </Wrap>
  );
};

export default withLayout(HomeView);

const Wrap = styled.div``;
