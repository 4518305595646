import React, { useEffect } from "react";
import withLayout from "layouts/DefaultLayout";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { Typography, Form, Input, Skeleton, List } from "antd";
import { Rule, FormItemProps } from "antd/lib/form";
import { EditOutlined } from "@ant-design/icons";

import { Item as TodoItem } from "components/Todo";

import { useQuery, useMutation } from "@apollo/react-hooks";
import { TODOS, CREATE_TODO, UPDATE_TODO } from "api/Todo";

const TodoView: React.FC<RouteComponentProps> = ({ user }) => {
  const { t } = useTranslation("Test");
  const { Title } = Typography;

  const { data: todoData, loading: todoLoading } = useQuery(TODOS, {
    onCompleted: data => {
      console.log(data, "data");
    },
    onError: err => {
      console.log(err, "err");
    }
  });

  const [CreateTodoMutation] = useMutation(CREATE_TODO, {
    refetchQueries: [{ query: TODOS }],
    onCompleted: () => {
      todoForm.resetFields();
    },
    onError: err => {
      console.log(err, "err");
    }
  });

  const [UpdateTodoMutation] = useMutation(UPDATE_TODO, {
    refetchQueries: [
      { query: TODOS },
      {
        query: TODOS,
        variables: {
          isArchive: true
        }
      }
    ],
    onError: err => {
      console.log(err, "err");
    }
  });

  const handleTodoUpdate = (props: ITodoItem) => {
    UpdateTodoMutation({
      variables: props
    });
  };

  const [todoForm] = Form.useForm();

  const rules: rulesBaseType<Rule> = {
    todo: [{ required: true, message: "값을 입력해주세요." }]
  };

  const formItems: FormItemProps[] = [
    {
      name: "todo",
      children: <Input placeholder={"해야할 일들을 입력하세요"} size={"large"} prefix={<EditOutlined />} />
    }
  ];

  const onFinish = (values: any) => {
    console.log("Success:", values);

    const { todo } = values;

    CreateTodoMutation({
      variables: {
        content: todo
      }
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {}, []);

  return (
    <Wrap>
      <Title level={3}>{user.nickName}의 해야하일</Title>
      <Form name="todo" form={todoForm} onFinish={onFinish} onFinishFailed={onFinishFailed}>
        {formItems.map((item, index) => (
          <Form.Item {...item} rules={rules[item.name as string]} key={index} />
        ))}
      </Form>
      <List
        bordered
        loading={todoLoading}
        dataSource={todoData?.todos as ITodoItem[]}
        renderItem={item => (
          <List.Item>
            <Skeleton title={false} paragraph={{ rows: 1, width: "100%" }} loading={todoLoading} active>
              <TodoItem {...item} callUpdate={handleTodoUpdate} />
            </Skeleton>
          </List.Item>
        )}
      />
    </Wrap>
  );
};

export default withLayout(TodoView);

const Wrap = styled.div``;
