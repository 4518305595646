import React, { useEffect } from "react";
import withLayout from "layouts/DefaultLayout";
import styled from "styles/theme";
import { Editor } from "styles";

const GuideView: React.FC<RouteComponentProps> = () => {
  useEffect(() => {}, []);

  return (
    <Wrap>
      <Editor />
    </Wrap>
  );
};

export default withLayout(GuideView);

const Wrap = styled.div``;
