import React from "react";
import styled from "styles/theme";
import { css } from "@emotion/core";

import { Checkbox, Button } from "antd";
import { DatabaseOutlined, UndoOutlined, CloseOutlined } from "@ant-design/icons";

import { dateDisplay } from "utils";

const TodoItemComponent: React.FC<ITodoItem> = ({ _id, isArchive, isComplete, content, createdAt, completeAt, callUpdate, callDelete }) => {
  const updateProps = {
    _id,
    isArchive,
    isComplete,
    content
  };

  const handleComplete = () => {
    callUpdate && callUpdate({ ...updateProps, isComplete: !isComplete });
  };

  const handleArchive = () => {
    callUpdate && callUpdate({ ...updateProps, isArchive: !isArchive });
  };

  const handleDelete = () => {
    const { _id } = updateProps;
    callDelete && callDelete(_id);
  };

  return (
    <TodoItem>
      <Checkbox id={_id} checked={isComplete} onChange={handleComplete}>
        <Complete isComplete={isComplete}>{content}</Complete>
      </Checkbox>
      <BtnArea>
        <Button icon={isArchive ? <UndoOutlined /> : <DatabaseOutlined />} onClick={handleArchive}>
          {isArchive ? "되돌리기" : "보관"}
        </Button>
        {isArchive && (
          <Button icon={<CloseOutlined />} onClick={handleDelete}>
            삭제
          </Button>
        )}
      </BtnArea>
      <AtInfo>
        {createdAt && dateDisplay(createdAt)}
        {completeAt && <CompleteAt>{dateDisplay(completeAt)}</CompleteAt>}
      </AtInfo>
    </TodoItem>
  );
};

export default TodoItemComponent;

const COMPLETE_CSS = css`
  text-decoration: line-through;
`;

const TodoItem = styled.div`
  display: flex;
  position: relative;
  padding-bottom: 26px;
  width: 100%;

  label {
    line-height: 32px;
  }

  & + div {
    margin-top: 10px;
  }
`;

const Complete = styled.span<ITodoItemStyle>`
  ${p => p.isComplete && COMPLETE_CSS}
`;

const BtnArea = styled.div`
  margin: 0 0 0 auto;

  & button + button {
    margin-left: 10px;
  }
`;

const AtInfo = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 0 0 26px;
  font-size: 12px;
`;

const CompleteAt = styled.strong`
  margin-left: auto;
  font-weight: bold;
`;
