import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import styled from "styles/theme";
import { css } from "@emotion/core";
import { getDefaultLocale } from "locales";

import { Layout, Menu, Breadcrumb, Avatar, Button } from "antd";
import { UserOutlined, BarsOutlined, DatabaseOutlined } from "@ant-design/icons";

import { LocalLink } from "styles";

import { gql } from "apollo-boost";
// import { ROOT } from "api/Client";
import { ME } from "api/User";

const LOG_OUT = gql`
  mutation userLogout {
    userLogout @client
  }
`;

export default (Component: React.FC<RouteComponentProps>) => (props: RouteComponentProps) => {
  const { Header, Content, Sider } = Layout;
  const { SubMenu } = Menu;

  const { match } = props;

  const navGnb: INav[] = [
    {
      path: "/todo",
      name: "Todo"
    },
    {
      path: "/chat",
      name: "Chat"
    },
    {
      path: "/bitcoin",
      name: "Bit Coin"
    },
    {
      path: "/board",
      name: "Board"
    }
  ];

  const subGnb: ISub = {
    todo: [
      {
        path: "/todo",
        name: "Todo",
        icon: <BarsOutlined />
      },
      {
        path: "/todo/archive",
        name: "Archive",
        icon: <DatabaseOutlined />
      }
    ],
    board: [
      {
        path: "/board",
        name: "Board",
        icon: <BarsOutlined />
      },
      {
        path: "/board/write",
        name: "Write",
        icon: <DatabaseOutlined />
      }
    ]
  };

  const userNav: INav[] = [
    {
      path: "/member",
      name: "Member"
    }
  ];

  const pathname = match.path.split(getDefaultLocale())[1]?.toLowerCase() as string;
  const currentNav = navGnb.filter(item => pathname?.includes(item.path))[0];
  const currentSub = currentNav && subGnb[currentNav.path.substr(1)] && subGnb[currentNav.path.substr(1)].filter(item => pathname === item.path)[0];
  const breadcrumb = ["Home"];

  currentNav && breadcrumb.push(currentNav.name);
  currentSub && currentSub.path.substr(0) !== currentNav.path.substr(0) && breadcrumb.push(currentSub.name);

  const winWidth = window.innerWidth;

  const [responsive, setResponsive] = useState<string>(winWidth > 768 ? "pc" : "mobile");
  const [userNavStatus, setUserNavStatus] = useState<boolean>(true);

  const NAV_GNB_TYPE = responsive === "pc" ? "horizontal" : "inline";
  const navGnbToggle = responsive === "mobile" ? true : false;

  const { data: userData, loading: userLoading } = useQuery(ME);
  const [logout] = useMutation(LOG_OUT);

  const handleResponsive = (broken: boolean) => {
    setResponsive(broken ? "mobile" : "pc");
  };

  // if (error?.message && cookie.get("id_token") && cookie.get("refresh_token")) {
  //   console.log(error?.message);
  //   error.message.indexOf("401") > 0 && auth.refreshToken(cookie.get("refresh_token"));
  // }

  const me = userData ? userData.me : {};

  useEffect(() => {
    // TODO 반응이 느려서 좀 더 빠르게 체크 가능한 곳에서 체크를 해야할거 같음 or Loading 상태에는 화면 바로 보여주지 않게 container에서 분기처리
    // if (!loading && data.me && data.me.id === null) history.push("/social");
  }, [userLoading, responsive]);

  return (
    me && (
      <Layout style={{ minHeight: "100%" }}>
        <StyledWrap currentSub={currentSub ? true : false} responsive={responsive}>
          <Header className={"header"}>
            <Sider className={"gnb"} width={"calc(100% - 100px)"} breakpoint="md" collapsedWidth="0" onBreakpoint={handleResponsive}>
              <Menu theme="dark" mode={NAV_GNB_TYPE} defaultSelectedKeys={currentNav && [currentNav.name]} inlineCollapsed={navGnbToggle}>
                {responsive === "pc" &&
                  navGnb.map(item => (
                    <Menu.Item key={item.name} icon={item.icon}>
                      {<LocalLink to={item.path}>{item.name}</LocalLink>}
                    </Menu.Item>
                  ))}
                {responsive === "mobile" &&
                  navGnb.map(item => (
                    <SubMenu key={item.name} title={item.name}>
                      {subGnb[item.path.substr(1)] &&
                        subGnb[item.path.substr(1)].map(item => (
                          <Menu.Item key={item.name} icon={item.icon}>
                            {<LocalLink to={item.path}>{item.name}</LocalLink>}
                          </Menu.Item>
                        ))}
                    </SubMenu>
                  ))}
              </Menu>
            </Sider>
            <UserArea responsive={responsive}>
              <Button className={"avatar"} type={"text"} onClick={() => setUserNavStatus(!userNavStatus)}>
                <Avatar icon={<UserOutlined />} />
              </Button>
              <Sider className={"user-nav"} width={150} reverseArrow={true} collapsed={userNavStatus} collapsedWidth="0">
                <Menu mode={"inline"} inlineCollapsed={true}>
                  {userNav.map(item => (
                    <Menu.Item key={item.name} icon={item.icon}>
                      <LocalLink to={item.path}>{item.name}</LocalLink>
                    </Menu.Item>
                  ))}
                  <Menu.Item onClick={() => logout()}>로그아웃</Menu.Item>
                </Menu>
              </Sider>
            </UserArea>
          </Header>
          <Layout>
            {currentSub && responsive === "pc" && (
              <Sider className={"lnb"} width={200} breakpoint="md" collapsedWidth="0">
                <Menu className={"menu"} mode="inline" defaultSelectedKeys={[currentSub.path]}>
                  {subGnb[currentNav.path.substr(1)].map(item => (
                    <Menu.Item key={item.path} icon={item.icon}>
                      {<LocalLink to={item.path}>{item.name}</LocalLink>}
                    </Menu.Item>
                  ))}
                </Menu>
              </Sider>
            )}
            <Layout className={"sub-layout"}>
              <Breadcrumb className={"breadcrumb"}>
                {breadcrumb.map((item, index) => (
                  <Breadcrumb.Item key={index}>{item}</Breadcrumb.Item>
                ))}
              </Breadcrumb>
              <Content className={"content"}>
                <Component {...props} user={me || {}} />
              </Content>
            </Layout>
          </Layout>
        </StyledWrap>
      </Layout>
    )
  );
};

interface ILayoutStyle {
  responsive: string;
  currentSub?: boolean;
}

const GNB_MOBILE = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
`;

const StyledWrap = styled.div<ILayoutStyle>`
  padding-top: ${p => (p.responsive === "mobile" ? 42 : 64)}px;
  width: 100%;
  min-height: 100%;

  & .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    ${p => p.responsive === "mobile" && "height: 42px;"}
  }

  & .gnb {
    ${p => p.responsive === "mobile" && GNB_MOBILE}

    & .ant-layout-sider-zero-width-trigger {
      top: 0;
      font-size: 25px;
    }
  }

  & .lnb {
    position: fixed;
    margin: 0;
    left: 0;
    padding: 24px;
    height: calc(100vh - 64px);
    background: #fff;
    overflow: auto;

    & .menu {
      height: 100%;
      border-right: 0;
    }
  }

  & .sub-layout {
    margin-left: ${p => (!p.currentSub ? 0 : p.responsive === "mobile" ? 0 : p.currentSub ? 200 : 0)}px;
    padding: 0 ${p => (p.responsive === "mobile" ? 12 : 24)}px 240px;

    & .breadcrumb {
      margin: 16px 0;
    }

    & .content {
      margin: 0;
      padding: ${p => (p.responsive === "mobile" ? 12 : 24)}px;
      background: #fff;
    }
  }
`;

const UserArea = styled.div<ILayoutStyle>`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: ${p => (p.responsive === "mobile" ? 5 : 20)}px;
  height: ${p => (p.responsive === "mobile" ? 42 : 64)}px;

  & .avatar {
    padding: 0;
  }

  & .user-nav {
    position: absolute;
    top: ${p => (p.responsive === "mobile" ? 42 : 64)}px;
    right: 0;
    z-index: 1000;
  }
`;
